import { NgModule } from "@angular/core";
import { BrowserModule } from "@angular/platform-browser";

import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { NavigationComponent } from "./navigation/navigation.component";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { HomeComponent } from "./features/main-application/pages/home/home.component";
import { SplitButtonModule } from "primeng/splitbutton";
import { MenubarModule } from "primeng/menubar";
import { InputTextModule } from "primeng/inputtext";
import { SidebarModule } from "primeng/sidebar";
import { PanelMenuModule } from "primeng/panelmenu";
import { CardModule } from "primeng/card";
import { CheckboxModule } from "primeng/checkbox";
import { LoginComponent } from "./auth/login/login.component";
import { HttpClientModule, HTTP_INTERCEPTORS } from "@angular/common/http";
import { JwtInterceptor } from "./_core/_interceptors/jwt.interceptor";
import { ErrorInterceptor } from "./_core/_interceptors/error.interceptor";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { MenuModule } from "primeng/menu";
import { ConfirmationService, MessageService } from "primeng/api";
import { ChartModule } from "primeng/chart";
import { StyleClassModule } from "primeng/styleclass";
import { TableModule } from "primeng/table";

import { TimelineModule } from "primeng/timeline";
import { ChipModule } from "primeng/chip";

import { TagModule } from "primeng/tag";
import { DateAsAgoPipe } from "./_share/_pipes/date-as-ago.pipe";
import { DropdownModule } from "primeng/dropdown";
import { InputTextareaModule } from "primeng/inputtextarea";
import { CalendarModule } from "primeng/calendar";
import { ChipsModule } from "primeng/chips";
import { AutoCompleteModule } from "primeng/autocomplete";
import { DividerModule } from "primeng/divider";

import { PrimeModule } from "./prime.module";
import { DialogService } from "primeng/dynamicdialog";
import { ForgotPasswordComponent } from "./auth/forgot-password/forgot-password/forgot-password.component";
import { MessageModule } from "primeng/message";
import { MessagesModule } from "primeng/messages";
import { RefreshComponent } from "./features/main-application/refresh/refresh.component";
import { RippleModule } from "primeng/ripple";
import { EmployeesComponent } from "./features/main-application/pages/employees/employees.component";
import { TransgressionsComponent } from "./features/main-application/pages/transgressions/transgressions.component";
import { IncidentsComponent } from "./features/main-application/pages/incidents/incidents.component";
import { CreateEmployeeComponent } from "./features/main-application/pages/create-employee/create-employee.component";
import { CreateTransgressionComponent } from "./features/main-application/pages/create-transgression/create-transgression.component";
import { CreateIncidentComponent } from "./features/main-application/pages/create-incident/create-incident.component";
import { ViewIncidentComponent } from "./features/main-application/pages/view-incident/view-incident.component";

@NgModule({
  declarations: [
    AppComponent,
    NavigationComponent,
    ForgotPasswordComponent,
    RefreshComponent,
    EmployeesComponent,
    TransgressionsComponent,
    IncidentsComponent,
    HomeComponent,
    LoginComponent,
    CreateEmployeeComponent,
    DateAsAgoPipe,
    CreateTransgressionComponent,
    CreateIncidentComponent,
    ViewIncidentComponent,
  ],
  imports: [
    HttpClientModule,
    BrowserModule,
    StyleClassModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    // prime module
    // todo: move modules from primeng into prime module

    PrimeModule,
    MessagesModule,
    MessageModule,
    MenubarModule,
    SplitButtonModule,
    InputTextModule,
    SidebarModule,
    PanelMenuModule,
    CardModule,
    CheckboxModule,
    FormsModule,
    ReactiveFormsModule,
    MenuModule,
    ChartModule,
    TableModule,
    TimelineModule,
    ChipModule,
    TagModule,
    DropdownModule,
    InputTextareaModule,
    CalendarModule,
    ChipsModule,
    AutoCompleteModule,
    DividerModule,
    RippleModule,
    // ToolbarModule
  ],
  providers: [
    DialogService,
    MessageService,
    ConfirmationService,
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
