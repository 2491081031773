import { Component, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { DynamicDialogConfig, DynamicDialogRef } from "primeng/dynamicdialog";
import { BehaviorSubject } from "rxjs";
import { Employee } from "src/app/_share/_models/employee";
import { EmployeeService } from "src/app/_share/_services/employee.service";

@Component({
  selector: "app-create-employee",
  templateUrl: "./create-employee.component.html",
  styleUrls: ["./create-employee.component.scss"],
})
export class CreateEmployeeComponent implements OnInit {
  createEmployees$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
    true
  );

  employeeForm: FormGroup = this.formBuilder.group({
    first_name: ["", Validators.required],
    last_name: ["", Validators.required],
    position: ["", Validators.required],
    employee_code: ["", Validators.required],
    department: ["", Validators.required],
    email: ["", [Validators.required, Validators.email]],
    phone_number: ["", Validators.required],
    status: ["", Validators.required],
  });

  editFormData: Employee | undefined;
  isInEditMode: boolean = false;
  editFormGroups: any;
  fb: any;
  // fb: any;
  // editFormGroups: any;
  // isInEditMode: boolean | undefined;

  constructor(
    public ref: DynamicDialogRef,
    public config: DynamicDialogConfig,
    public employeeService: EmployeeService,
    public formBuilder: FormBuilder
  ) {}

  ngOnInit(): void {
    // Simulate loading completion
    setTimeout(() => {
      this.createEmployees$.next(false);
    }, 1000);
    this.updateFormData();
  }
  // Updates the form data if in edit mode
  xupdateFormData() {
    this.editFormData = this.config.data?.formdata;

    if (this.editFormData) {
      this.employeeForm.patchValue(this.editFormData);
      this.isInEditMode = true;
    } else {
      this.isInEditMode = false;
    }
  }
  //Updates the form data if in edit mode
  updateFormData() {
    this.editFormData = this.config.data?.formdata;
    if (this.editFormData) {
      if (Array.isArray(this.editFormData)) {
        if (this.editFormData.length > 0) {
          this.editFormData.forEach((desc: any, index: number) => {
            const formGroup = this.fb.group({
              first_name: desc.first_name,
              last_name: desc.last_name,
              position: desc.position,
              department: desc.department,
              email: desc.email,
              phone_number: desc.phone_number,
              status: desc.status,
            });
            this.editFormGroups.push(formGroup);
            this.isInEditMode = true;
          });
        }
      } else {
        this.employeeForm.patchValue(this.editFormData);
        this.isInEditMode = true;
      }
    } else {
      this.isInEditMode = false;
    }
  }

  onAddEmployeeFormSubmit() {
    if (this.employeeForm.invalid) {
      this.employeeForm.markAllAsTouched();
      return;
    }
    if (this.isInEditMode && this.editFormData?.id !== undefined) {
      // called .value on form data after assigning form values to updatedFormData, thus you were trying to extract this.employeeForm.value.value effectively
      this.employeeService
        .updateEmployee(this.editFormData.id, this.employeeForm.value)
        .subscribe({
          next: (res: any) => {
            console.log("Employee updated successfully", res);
            this.ref.close(this.employeeForm.value);
          },
          error: (err: any) => {
            console.error("Error updating employee", err);
          },
        });
    } else {
      this.employeeService.addEmployee(this.employeeForm.value).subscribe({
        next: (res: any) => {
          console.log("Employee added successfully", res);
          this.employeeService.refreshEmployees();
          this.ref.close(this.employeeForm.value);
        },
        error: (err: any) => {
          console.error("Error adding employee", err);
        },
      });
    }
  }
}
