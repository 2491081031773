import {
  ChangeDetectionStrategy,
  Component,
  HostListener,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
} from "@angular/core";
import { BreakpointObserver, Breakpoints } from "@angular/cdk/layout";
import { BehaviorSubject, Observable } from "rxjs";
import { map, shareReplay } from "rxjs/operators";
import { MenuItem } from "primeng/api";
import { AuthenticationService } from "../_core/_services/authentication.service";
import { UserService } from "../_share/_services/user.service";

@Component({
  selector: "app-navigation",
  templateUrl: "./navigation.component.html",
  styleUrls: ["./navigation.component.scss"],
})
export class NavigationComponent implements OnInit {
  profileitems: MenuItem[];
  @Input() user: any = this.authenticationService.userValue;
  auth$ = this.authenticationService.user;
  user$: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  sideMenuItems: MenuItem[] = [];
  sidebarVisible: boolean = true;
  visibleSidebar = false;
  isMobile = false;

  showFiller = false;
  constructor(
    private readonly breakpointObserver: BreakpointObserver,
    public authenticationService: AuthenticationService,
    public userService: UserService
  ) {
    this.userService.getMe().subscribe((user) => {
      this.user = user;
      this.user$.next(user);
    });
    this.sideMenuItems = [
      { label: "Home", routerLink: "/", icon: "pi pi-fw pi-home" },
      {
        label: "Incidents",
        routerLink: "/incidents",
        icon: "pi pi-fw pi-info-circle",
      },
      {
        label: "Hearings",
        routerLink: "/hearings",
        icon: "pi-user-minus",
      },
      {
        label: "Transgressions",
        routerLink: "/transgressions",
        icon: "pi pi-fw pi-exclamation-triangle",
      },
      {
        label: "Employees",
        routerLink: "/employees",
        icon: "pi pi-fw pi-users",
      },
    ];

    this.profileitems = [
      {
        label: "Logout",
        command: () => {
          this.authenticationService.logout();
        },
      },
    ];
  }
  ngOnInit() {
    this.checkScreenWidth();
    this.userService.getMe().subscribe((user) => {
      this.user = user;
      this.user$.next(user);
    });
  }

  @HostListener("window:resize", ["$event"])
  onResize(event: Event) {
    this.checkScreenWidth();
  }

  checkScreenWidth() {
    this.isMobile = window.innerWidth <= 768;
  }

  toggleSidebar() {
    this.visibleSidebar = !this.visibleSidebar;
  }

  hideSidebar() {
    this.visibleSidebar = false;
  }

  isHandset$: Observable<boolean> = this.breakpointObserver
    .observe(Breakpoints.Handset)
    .pipe(
      map((result) => result.matches),
      shareReplay(1)
    );
}
