<div class="flex flex-column md:flex-column">
  <div class="grid">
    <div class="col-12 md:col-6 lg:col-3">
      <div class="surface-card shadow-2 p-3">
        <div class="flex justify-content-between mb-3">
          <div>
            <span class="block text-500 font-medium mb-3">Employees</span>
            <div
              class="text-900 font-medium text-xl"
              *ngIf="
                this.employees$ | async as employeeCountObject;
                else loadingEmployeeCount
              "
            >
              {{ employeeCountObject.count }}
            </div>
            <ng-template #loadingEmployeeCount>
              <p-skeleton width="100%" height="1.5rem"></p-skeleton>
            </ng-template>
          </div>
          <div
            class="flex align-items-center justify-content-center bg-blue-100 border-round"
            style="width: 2.5rem; height: 2.5rem"
          >
            <i class="pi pi-users text-blue-500 text-xl"></i>
          </div>
        </div>
        <!-- todo: improve on this -->
        <span class="text-green-500 font-medium">24 new </span>
        <span class="text-500">in the last month</span>
      </div>
    </div>
    <div class="col-12 md:col-6 lg:col-3">
      <div class="surface-card shadow-2 p-3 border-round">
        <div class="flex justify-content-between mb-3">
          <div>
            <span class="block text-500 font-medium mb-3">Incidents</span>
            <div
              class="text-900 font-medium text-xl"
              *ngIf="
                this.incidents$ | async as incidentsCount;
                else loadingIncidentsCount
              "
            >
              {{ incidentsCount.count }}
            </div>
            <ng-template #loadingIncidentsCount>
              <p-skeleton width="100%" height="1.5rem"></p-skeleton>
            </ng-template>
          </div>
          <div
            class="flex align-items-center justify-content-center bg-orange-100 border-round"
            style="width: 2.5rem; height: 2.5rem"
          >
            <i class="pi pi-exclamation-triangle text-red-500 text-xl"></i>
          </div>
        </div>
        <span
          class="text-green-500 font-medium"
          *ngIf="
            this.incidents$ | async as incidentsCount;
            else loadingIncidentsOpenCount
          "
          >{{ (incidents$ | async).open_count }}</span
        >
        <ng-template #loadingIncidentsOpenCount>
          <p-skeleton width="1.5rem" height="1.5rem"></p-skeleton>
        </ng-template>
        <span class="text-500"> currently OPEN</span>
      </div>
    </div>
    <div class="col-12 md:col-6 lg:col-3">
      <div class="surface-card shadow-2 p-3 border-round">
        <div class="flex justify-content-between mb-3">
          <div>
            <span class="block text-500 font-medium mb-3"
              >Transgression Types</span
            >
            <div
              class="text-900 font-medium text-xl"
              *ngIf="
                this.transgressions$ | async as transgressionsCount;
                else loadingTransgressionsCount
              "
            >
              {{ transgressionsCount.count }}
            </div>
            <ng-template #loadingTransgressionsCount>
              <p-skeleton width="100%" height="1.5rem"></p-skeleton>
            </ng-template>
          </div>
          <div
            class="flex align-items-center justify-content-center bg-yellow-200"
            style="width: 2.5rem; height: 2.5rem"
          >
            <i class="pi pi-exclamation-circle text-orange-500 text-xl"></i>
          </div>
        </div>
        <span class="text-500">4 categories</span>
      </div>
    </div>

    <div class="col-12 md:col-6 lg:col-3" *ngIf="false">
      <div class="surface-card shadow-2 p-3 border-round">
        <div class="flex justify-content-between mb-3">
          <div>
            <span class="block text-500 font-medium mb-3">Comments</span>
            <div class="text-900 font-medium text-xl">152 Unread</div>
          </div>
          <div
            class="flex align-items-center justify-content-center bg-purple-100 border-round"
            style="width: 2.5rem; height: 2.5rem"
          >
            <i class="pi pi-comment text-purple-500 text-xl"></i>
          </div>
        </div>
        <span class="text-green-500 font-medium">85 </span>
        <span class="text-500">responded</span>
      </div>
    </div>
  </div>
</div>
