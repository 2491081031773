<div class="navigation-shell">
  <p-sidebar
    [(visible)]="visibleSidebar"
    [baseZIndex]="10000"
    [modal]="true"
    [fullScreen]="false"
    [blockScroll]="true"
  >
    <!-- mobile menu -->
    <div class="sidebar-content">
      <ng-container *ngIf="auth$ | async as auth">
        <ul class="list-none p-0 m-0">
          <li *ngFor="let menuItem of sideMenuItems">
            <a
              pRipple
              class="flex align-items-center justify-content-center cursor-pointer p-3 hover:bg-gray-700 hover:border-round border-round text-bluegray-600 hover:text-bluegray-50 transition-duration-150 transition-colors"
              [routerLinkActive]="'bg-yellow-500 text-gray-900 border-round'"
              [routerLinkActiveOptions]="{ exact: true }"
              [routerLink]="[menuItem.routerLink]"
              (click)="hideSidebar()"
              style="text-decoration: none"
            >
              <i
                class="pi {{
                  menuItem.icon
                }} mr-2 lg:mr-0 text-base lg:text-2xl "
              ></i>
              <span class="font-medium inline text-base lg:text-xs lg:hidden">{{
                menuItem.label
              }}</span>
            </a>
          </li>
        </ul>
        <p-divider></p-divider>
        <ng-container *ngIf="user$ | async as user">
          <ul class="list-none p-0 m-0 align-items-center flex flex-column">
            <li *ngIf="user">
              <p-avatar pBadge icon="pi pi-user" shape="circle"></p-avatar>

              <span class="font-medium inline mx-2">{{ user.first_name }}</span>
            </li>
            <li>
              <a
                pRipple
                class="flex align-items-center justify-content-center cursor-pointer p-3 hover:bg-gray-700 border-round text-bluegray-600 hover:text-bluegray-50 transition-duration-150 transition-colors"
                (click)="this.authenticationService.logout(); toggleSidebar()"
                style="text-decoration: none"
              >
                <i
                  class="pi pi-sign-out mr-2 lg:mr-0 text-base lg:text-2xl text-pink-500"
                ></i>
                <span class="font-medium inline lg:hidden">Sign Out</span>
              </a>
            </li>
          </ul>
        </ng-container>
      </ng-container>
    </div>
  </p-sidebar>

  <!-- large screen menu -->
  <div class="static-sidebar" *ngIf="!isMobile" class="bg-gray-300">
    <div class="sidebar-content flex flex-column h-full">
      <div
        class="flex align-items-center justify-content-center px-5 text-gray-100 bg-gray-300 flex-shrink-0 text-lg"
        style="height: 60px"
      >
        <!-- <span>YSM</span> -->
        <img src="../../assets/images/logo.png" alt="logo" height="48" />
      </div>
      <ng-container *ngIf="auth$ | async as auth">
        <ul class="list-none p-0 m-0 flex-1">
          <li
            [routerLinkActive]="' bg-yellow-500 text-gray-200 border-round'"
            [routerLinkActiveOptions]="{ exact: true }"
            *ngFor="let menuItem of sideMenuItems"
          >
            <a
              pRipple
              class="flex align-items-center justify-content-center cursor-pointer p-3 hover:bg-yellow-500 border-round text-gray-900 hover:text-bluegray-50 transition-duration-150 transition-colors"
              [routerLink]="[menuItem.routerLink]"
              (click)="hideSidebar()"
              style="text-decoration: none"
            >
              <i
                class="pi {{
                  menuItem.icon
                }} lg:mr-0 text-base lg:text-2xl align-self-center"
              ></i>
            </a>
          </li>
        </ul>
        <ng-container *ngIf="user$ | async as user">
          <ul class="list-none p-0 m-0 align-items-center flex flex-column">
            <li *ngIf="user" class="py-1">
              <p-avatar pBadge icon="pi pi-user" shape="circle"></p-avatar>

              <span class="font-medium inline mx-2">{{ user.first_name }}</span>
            </li>
            <li>
              <a
                pRipple
                class="flex align-items-center justify-content-center cursor-pointer p-3 hover:bg-gray-700 border-round text-bluegray-600 hover:text-bluegray-50 transition-duration-150 transition-colors"
                (click)="this.authenticationService.logout()"
                style="text-decoration: none"
              >
                <i
                  class="pi pi-sign-out mr-2 lg:mr-0 text-base lg:text-2xl text-pink-500"
                ></i>
                <span class="font-medium inline lg:hidden">Sign Out</span>
              </a>
            </li>
          </ul>
        </ng-container>
        <!-- <div class="mt-auto">
          <hr class="mx-3 border-top-1 border-none surface-border" />
          <a
            *ngIf="user$ | async"
            pRipple
            class="flex align-items-center justify-content-center my-3 flex cursor-pointer p-3 hover:bg-pink-800 border-round transition-duration-150 transition-colors"
            (click)="this.authenticationService.logout()"
            pStyleClass="#app-sidebar-2"
            enterClass="hidden"
            enterActiveClass="fadeinleft"
            leaveToClass="hidden"
            leaveActiveClass="fadeoutleft"
          >
            <i
              class="pi pi-sign-out mr-2 lg:mr-0 text-base lg:text-2xl text-pink-500"
            ></i>
            <span class="font-medium inline lg:hidden">Sign Out</span>
          </a>
        </div> -->
      </ng-container>
    </div>
  </div>

  <div class="content-wrapper">
    <div class="mobile-top-bar" *ngIf="isMobile" class="bg-yellow-500">
      <button
        type="button"
        class="bg-yellow-500 text-gray-900 p-3"
        pButton
        icon="pi pi-bars"
        (click)="toggleSidebar()"
      ></button>
    </div>
    <div class="main-content">
      <router-outlet></router-outlet>
    </div>
  </div>
</div>
