<div *ngIf="this.Incidents$ | async; else loadedContent">
  <div class="surface-card shadow-2 flex p-3 flex-column">
    <div class="formgrid p-fluid px-4 py-5 md:px-6 lg:px-8">
      <div class="field col-12">
        <p-skeleton width="100%" height="20px" styleClass="my-2"></p-skeleton>
        <p-skeleton width="100%" height="20px" styleClass="my-2"></p-skeleton>
        <p-skeleton width="100%" height="80px" styleClass="py-4"></p-skeleton>
        <p-skeleton width="100%" height="80px" styleClass="py-4"></p-skeleton>
      </div>
      <div class="field col-12">
        <p-skeleton width="100%" height="20px" styleClass="my-2"></p-skeleton>
        <p-skeleton width="100%" height="20px" styleClass="my-2"></p-skeleton>
        <p-skeleton width="100%" height="80px" styleClass="py-4"></p-skeleton>
      </div>
    </div>
  </div>
</div>

<ng-template #loadedContent>
  <div class="px-3 py-3 md:px-6 lg:px-8">
    <div class="grid">
      <!-- Resolution Block -->
      <div class="col-12 lg:col-6">
        <div class="surface-card p-4 shadow-2 border-round">
          <div class="flex justify-content-between">
            <span class="font-bold text-900">Incident Status:</span>
            <div class="card">
              <p-badge
                [value]="incident.incident_status"
                severity="success"
                class="w-24"
              ></p-badge>
            </div>
          </div>
        </div>
      </div>

      <!-- Employee Block -->
      <div class="col-12 lg:col-6 mt-2 lg:mt-0">
        <div class="surface-card p-4 shadow-2 border-round">
          <div class="flex justify-content-between">
            <span class="font-bold text-900">Employee:</span>
            <div class="card">
              <p-badge
                [value]="getEmployeeNameById(incident.employee_id)"
                severity="info"
                class="w-24"
              ></p-badge>
            </div>
          </div>
        </div>
      </div>

      <!-- Reason Block -->
      <div class="col-12 lg:col-6">
        <div class="surface-card p-4 shadow-2 border-round">
          <div class="flex justify-content-between">
            <span class="font-bold text-900">Reason:</span>
            <div class="card">
              <p-badge
                [value]="incident.description"
                severity="info"
                class="w-24"
              ></p-badge>
            </div>
          </div>
          <div class="flex justify-content-between mt-2">
            <span class="font-bold text-900">Action Taken:</span>
            <div class="card">
              <p-badge
                [value]="incident.action_taken"
                severity="danger"
                class="w-24"
              ></p-badge>
            </div>
          </div>
        </div>
      </div>

      <!-- Transgression Block -->
      <div class="col-12 lg:col-6 mt-2 lg:mt-0">
        <div class="surface-card p-4 shadow-2 border-round">
          <div class="flex justify-content-between">
            <span class="font-bold text-900">Category Level:</span>
            <div class="card">
              <p-badge
                [value]="incident.category"
                severity="info"
                class="w-24"
              ></p-badge>
            </div>
          </div>
          <div class="flex justify-content-between mt-2">
            <span class="font-bold text-900">Transgression:</span>
            <div class="card">
              <p-badge
                [value]="getTransgressionOffenceById(incident.transgression_id)"
                severity="warning"
                class="w-24"
              ></p-badge>
            </div>
          </div>
          <div class="flex justify-content-between mt-2">
            <span class="font-bold text-900">Penalty:</span>
            <div class="card">
              <p-badge
                [value]="getTransgressionPenaltyById(incident.transgression_id)"
                severity="danger"
                class="w-24"
              ></p-badge>
            </div>
          </div>
        </div>
      </div>

      <!-- Employee Incident History Block -->
      <div class="col-12 lg:col-6 mt-1 lg:-mt-5">
        <div class="surface-card p-4 shadow-2 border-round">
          <div class="font-bold text-900">Employee Incident History:</div>
          <ul>
            <li *ngFor="let history of employeeIncidentHistory">
              <div class="flex justify-content-between">
                <span>{{ history.category }}</span>
                <span class="text-sm text-gray-500">{{
                  history.date | date
                }}</span>
              </div>
            </li>
          </ul>
        </div>
      </div>
      <!-- Incident Actions Block -->
      <div class="col-12 lg:col-6 mt-2 lg:mt-0">
        <div class="surface-card p-4 shadow-2 border-round">
          <div class="font-bold text-900">Incident Progression:</div>
          <p-timeline [value]="incidentActions" class="p-1">
            <ng-template pTemplate="content" let-action>
              <small class="p-text-secondary">{{
                action.action_date | date : "YYYY-MM-dd"
              }}</small>
            </ng-template>
            <ng-template pTemplate="opposite" let-action>
              {{ action.action_type }}
            </ng-template>
          </p-timeline>
        </div>
      </div>
      <!-- end of Incident Actions Block -->

      <!-- Files Block -->
      <div class="col-12 lg:col-6 mt-2 lg:mt-0">
        <div class="surface-card p-4 shadow-2 border-round">
          <div class="font-bold text-900">Files:</div>
          <ul>
            <li *ngFor="let file of getFileLinks(incident.files)">
              <a
                href="javascript:void(0);"
                (click)="
                  downloadFile(
                    file.unique_file_reference,
                    file.original_filename
                  );
                  $event.stopPropagation()
                "
                >{{ file.original_filename }}</a
              >
            </li>
          </ul>
        </div>
      </div>
      <!-- end of Files Block -->
      <div class="col-12 lg:col-6 mt-2 lg:mt-0">
        <div class="surface-card p-4 shadow-2 border-round">
          <div [formGroup]="incidentForm">
            <label for="incidentResolution">Resolution</label>
            <div class="flex align-items-center">
              <p-dropdown
                formControlName="resolution"
                [options]="resolutionOptions"
                optionLabel="label"
                optionValue="value"
                placeholder="Select a Resolution"
              ></p-dropdown>
              <div
                *ngIf="
                  incidentForm.get('resolution')?.invalid &&
                  (incidentForm.get('resolution')?.touched ||
                    incidentForm.get('resolution')?.dirty)
                "
                class="px-1 py-1"
              >
                <small class="p-error block"
                  ><strong>Resolution</strong> is required</small
                >
              </div>
            </div>

            <button
              pButton
              label="Conclude Incident"
              class="p-button-success mt-2"
              (click)="concludeIncident()"
            ></button>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-template>
