import { Component, OnDestroy, OnInit } from "@angular/core";
import { EmployeeService } from "src/app/_share/_services/employee.service";
import { Employee } from "src/app/_share/_models/employee";
import { BehaviorSubject, Observable, Subscription } from "rxjs";
import { DialogService, DynamicDialogRef } from "primeng/dynamicdialog";
import { ConfirmationService, MessageService } from "primeng/api";
import { CreateEmployeeComponent } from "../create-employee/create-employee.component";
import { ViewEmployeeComponent } from "../view-employee/view-employee.component";

@Component({
  selector: "app-employees",
  templateUrl: "./employees.component.html",
  styleUrls: ["./employees.component.scss"],
  providers: [ConfirmationService, MessageService],
})
export class EmployeesComponent implements OnInit, OnDestroy {
  selectedRowCMObservable$: BehaviorSubject<any> = new BehaviorSubject<any>(
    null
  );
  Employees$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
  ref: DynamicDialogRef | undefined;
  employees: any[] = [];
  employeesObservable$: any;
  private subscriptions: Subscription = new Subscription();

  constructor(
    public dialogService: DialogService,
    public employeeService: EmployeeService,
    private messageService: MessageService,
    private confirmationService: ConfirmationService
  ) {}

  ngOnInit(): void {
    setTimeout(() => {
      this.Employees$.next(false);
    }, 3000);
    this.subscriptions.add(
      this.employeeService.getEmployees().subscribe((employees) => {
        this.employees = employees;
      })
    );
  }
  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  getEmployeesOptionsList() {
    this.subscriptions.add(
      this.employeeService.getEmployees().subscribe((employees: any[]) => {
        this.employees = employees;
      })
    );
  }
  //ngdestroy
  //subject to table instead of list
  //employees$ = new BehaviorSubject<Employee[]>([]);
  columns = [
    { field: "employee_code", header: "Employee Code" },
    { field: "first_name", header: "First Name" },
    { field: "last_name", header: "Last Name" },
    { field: "position", header: "Position" },
    { field: "department", header: "Department" },
    { field: "email", header: "Email" },
    { field: "phone_number", header: "Phone Number" },
    { field: "status", header: "Status" },
  ];

  showAdd() {
    this.ref = this.dialogService.open(CreateEmployeeComponent, {
      data: {
        employeeData: null,
        listofemployees: this.employeeService.getEmployees(),
      },
      header: "Add Employee",
      width: "65%",
      height: "65%",
      closeOnEscape: true,
    });

    this.ref.onClose.subscribe((result) => {
      if (result) {
        this.getEmployeesOptionsList();
        this.messageService.add({
          severity: "success",
          summary: "Success",
          detail: "Employee successfully added",
        });
      }
    });
  }

  showViewEmployee(rowData: any) {
    this.ref = this.dialogService.open(ViewEmployeeComponent, {
      data: rowData,

      header: "Employee Details",
      width: "90%",
      height: "70%",
      closeOnEscape: true,
    });
  }

  // Edit function
  editEmployee(rowData: any) {
    console.log("row", rowData);
    this.ref = this.dialogService.open(CreateEmployeeComponent, {
      data: {
        formdata: rowData,
      },
      header: "Edit Employee",
      width: "70%",
      height: "60%",
      closeOnEscape: true,
    });
    this.ref.onClose.subscribe((result) => {
      if (result) {
        this.getEmployeesOptionsList();
        this.messageService.add({
          severity: "success",
          summary: "Success",
          detail: "Employee updated successfully",
        });
      }
    });
  }

  deleteEmployee(rowData: any) {
    this.confirmationService.confirm({
      message: "Are you sure you want to delete this employee?",
      header: "Delete Confirmation",
      icon: "pi pi-info-circle",
      accept: () => {
        this.employeeService.deleteEmployee(rowData.id).subscribe(() => {
          this.getEmployeesOptionsList();
          this.messageService.add({
            severity: "success",
            summary: "Success",
            detail: "Employee deleted successfully",
          });
        });
      },
      reject: () => {
        this.messageService.add({
          severity: "info",
          summary: "Rejected",
          detail: "Employee was not deleted",
        });
      },
    });
  }
}
