import { Component, OnDestroy, OnInit } from "@angular/core";
import { BehaviorSubject, Observable, Subscription } from "rxjs";
import { DialogService, DynamicDialogRef } from "primeng/dynamicdialog";
import { ConfirmationService, MessageService } from "primeng/api";
import { TransgressionService } from "src/app/_share/_services/transgression.service";
import { CreateTransgressionComponent } from "../create-transgression/create-transgression.component";

@Component({
  selector: "app-transgressions",
  templateUrl: "./transgressions.component.html",
  styleUrls: ["./transgressions.component.scss"],
  providers: [ConfirmationService, MessageService],
})
export class TransgressionsComponent implements OnInit, OnDestroy {
  selectedRowCMObservable$: BehaviorSubject<any> = new BehaviorSubject<any>(
    null
  );
  Transgressions$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
    true
  );
  ref: DynamicDialogRef | undefined;
  transgressions: any[] = [];
  transgressionsObservable$: any;
  private subscriptions: Subscription = new Subscription();

  constructor(
    public dialogService: DialogService,
    public transgressionService: TransgressionService,
    private messageService: MessageService,
    private confirmationService: ConfirmationService
  ) {}

  ngOnInit(): void {
    setTimeout(() => {
      this.Transgressions$.next(false);
    }, 3000);
    this.subscriptions.add(
      this.transgressionService
        .getTransgressions()
        .subscribe((transgressions) => {
          this.transgressions = transgressions;
        })
    );
  }
  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  getTransgressionsOptionsList() {
    this.subscriptions.add(
      this.transgressionService
        .getTransgressions()
        .subscribe((transgressions: any[]) => {
          this.transgressions = transgressions;
        })
    );
  }
  columns = [
    { field: "category", header: "Category" },
    { field: "standard", header: "Standard" },
    { field: "offence", header: "Offence" },
    { field: "offence_penalty", header: "Offence Penalty" },
  ];
  // Method to map offence_penalty values to their respective text
  getPenaltyText(penalty: number): string {
    switch (penalty) {
      case 1:
        return "Verbal Warning";
      case 2:
        return "Written Warning";
      case 3:
        return "Final Warning";
      case 4:
        return "Termination";
      default:
        return "Unknown Penalty";
    }
  }

  showAdd() {
    this.ref = this.dialogService.open(CreateTransgressionComponent, {
      data: {
        transgressionData: null,
        listoftransgressions: this.transgressionService.getTransgressions(),
      },
      header: "Add Transgression",
      width: "65%",
      height: "65%",
      closeOnEscape: true,
    });

    this.ref.onClose.subscribe((result) => {
      if (result) {
        this.getTransgressionsOptionsList();
        this.messageService.add({
          severity: "success",
          summary: "Success",
          detail: "Transgression successfully added",
        });
      }
    });
  }

  // Edit function
  editTransgression(rowData: any) {
    console.log("row", rowData);
    this.ref = this.dialogService.open(CreateTransgressionComponent, {
      data: {
        formdata: rowData,
      },
      header: "Edit Transgression",
      width: "60%",
      height: "60%",
      closeOnEscape: true,
    });
    this.ref.onClose.subscribe((result) => {
      if (result) {
        this.getTransgressionsOptionsList();
        this.messageService.add({
          severity: "success",
          summary: "Success",
          detail: "Transgression updated successfully",
        });
      }
    });
  }

  deleteTransgression(rowData: any) {
    this.confirmationService.confirm({
      message: "Are you sure you want to delete this transgression?",
      header: "Delete Confirmation",
      icon: "pi pi-info-circle",
      accept: () => {
        this.transgressionService
          .deleteTransgression(rowData.id)
          .subscribe(() => {
            this.getTransgressionsOptionsList();
            this.messageService.add({
              severity: "success",
              summary: "Success",
              detail: "Transgression deleted successfully",
            });
          });
      },
      reject: () => {
        this.messageService.add({
          severity: "info",
          summary: "Rejected",
          detail: "Transgression was not deleted",
        });
      },
    });
  }
}
