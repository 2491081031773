<div class="flex flex-column flex-auto">
  <div class="grid">
    <div class="col-12">
      <div class="surface-card shadow-2 flex p-3 flex-column md:flex-row">
        <div class="flex align-items-center">
          <i class="text-900 text-2xl mr-2"></i>
          <span class="text-900 font-medium text-2xl">Home</span>
        </div>
        <div class="flex flex-1 justify-content-end align-items-center"></div>
      </div>
    </div>
    <div class="col-12">
      <div class="surface-card shadow-2 flex p-3 flex-column md:flex-row">
        <!-- fleet/vehicle stats -->
      </div>
    </div>
  </div>
</div>
