<div class="navigation-shell">
  <p-sidebar
    [(visible)]="visibleSidebar"
    [baseZIndex]="10000"
    [modal]="true"
    [fullScreen]="false"
    [blockScroll]="true"
  >
    <!-- mobile menu -->
    <div class="sidebar-content">
      <ul class="list-none p-0 m-0">
        <li *ngFor="let menuItem of sideMenuItems">
          <a
            pRipple
            class="flex align-items-center justify-content-center cursor-pointer p-3 hover:bg-gray-700 hover:border-round border-round text-bluegray-600 hover:text-bluegray-50 transition-duration-150 transition-colors"
            [routerLinkActive]="'bg-yellow-500 text-gray-900 border-round'"
            [routerLinkActiveOptions]="{ exact: true }"
            [routerLink]="[menuItem.routerLink]"
            (click)="hideSidebar()"
            style="text-decoration: none"
          >
            <i
              class="pi {{ menuItem.icon }} mr-2 lg:mr-0 text-base lg:text-2xl "
            ></i>
            <span class="font-medium inline text-base lg:text-xs lg:hidden">{{
              menuItem.label
            }}</span>
          </a>
        </li>
      </ul>
    </div>
  </p-sidebar>

  <!-- large screen menu -->
  <div class="static-sidebar" *ngIf="!isMobile" class="bg-gray-300">
    <div class="sidebar-content">
      <div
        class="flex align-items-center justify-content-center px-5 text-gray-100 bg-gray-300 flex-shrink-0 text-lg"
        style="height: 60px"
      >
        <!-- <span>YSM</span> -->
        <img
          src="https://ysmfoundry.co.za/wp-content/uploads/2023/02/logo.png"
          alt="logo"
          height="48"
        />
      </div>
      <ul class="list-none p-0 m-0">
        <li
          [routerLinkActive]="' bg-yellow-500 text-gray-200 border-round'"
          [routerLinkActiveOptions]="{ exact: true }"
          *ngFor="let menuItem of sideMenuItems"
        >
          <a
            pRipple
            class="flex align-items-center justify-content-center cursor-pointer p-3 hover:bg-yellow-500 border-round text-gray-900 hover:text-bluegray-50 transition-duration-150 transition-colors"
            [routerLink]="[menuItem.routerLink]"
            (click)="hideSidebar()"
            style="text-decoration: none"
          >
            <i
              class="pi {{
                menuItem.icon
              }} lg:mr-0 text-base lg:text-2xl align-self-center"
            ></i>
          </a>
        </li>
      </ul>
    </div>
  </div>

  <div class="content-wrapper">
    <div class="mobile-top-bar" *ngIf="isMobile" class="bg-yellow-500">
      <button
        type="button"
        class="bg-yellow-500 text-gray-900 p-3"
        pButton
        icon="pi pi-bars"
        (click)="toggleSidebar()"
      ></button>
    </div>
    <div class="main-content">
      <router-outlet></router-outlet>
    </div>
  </div>
</div>
