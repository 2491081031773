<div
  *ngIf="this.createTransgressions$ | async; else loadedContent"
  class="formgrid p-fluid px-4 py-5 md:px-6 lg:px-8"
>
  <!-- Skeleton loader or loading state -->
  <div class="field col-12">
    <p-skeleton width="100%" height="20px" styleClass="my-2"></p-skeleton>
    <p-skeleton width="100%" height="20px" styleClass="my-2"></p-skeleton>
    <p-skeleton width="100%" height="80px" styleClass="py-4"></p-skeleton>
  </div>
  <div class="field col-12">
    <p-skeleton width="100%" height="20px" styleClass="my-2"></p-skeleton>
    <p-skeleton width="100%" height="20px" styleClass="my-2"></p-skeleton>
    <p-skeleton width="100%" height="80px" styleClass="py-4"></p-skeleton>
  </div>
  <div class="field col-12">
    <p-skeleton width="100%" height="20px" styleClass="my-2"></p-skeleton>
    <p-skeleton width="100%" height="20px" styleClass="my-2"></p-skeleton>
  </div>


</div>

<ng-template #loadedContent>
  <form [formGroup]="transgressionForm" (ngSubmit)="onAddTransgressionFormSubmit()">
    <div class="surface-section px-4 py-4 md:px-6 lg:px-8">
      <div class="grid formgrid p-fluid">
        <div class="field mb-4 col-12 lg:col-6">
          <label for="transgressionCategory">Category</label>
          <input
            formControlName="category"
            type="text"
            class="form-control"
            id="transgressionCategory"
            placeholder="Enter category severity"
            pInputTextarea
            rows="5"
            cols="30"
          />
          <div
            *ngIf="transgressionForm.get('category')?.invalid && (transgressionForm.get('category')?.touched || transgressionForm.get('category')?.dirty)"
            class="px-1 py-1"
          >
            <small class="p-error block"><strong>Category</strong> is required</small>
          </div>
        </div>
        <div class="field mb-4 col-12 lg:col-6">
          <label for="transgressionStandard">Standard</label>
          <input
            formControlName="standard"
            type="text"
            class="form-control"
            id="transgressionStandard"
            placeholder="Enter standard"
            pInputTextarea
            rows="5"
            cols="30"
          />
          <div
            *ngIf="transgressionForm.get('standard')?.invalid && (transgressionForm.get('standard')?.touched || transgressionForm.get('standard')?.dirty)"
            class="px-1 py-1"
          >
            <small class="p-error block"><strong>Standard</strong> is required</small>
          </div>
        </div>
        <div class="field mb-4 col-12 lg:col-6">
          <label for="transgressionOffence">Offence</label>
          <input
            formControlName="offence"
            type="text"
            class="form-control"
            id="transgressionOffence"
            placeholder="Enter offence"
            pInputTextarea
            rows="5"
            cols="30"
          />
          <div
            *ngIf="transgressionForm.get('offence')?.invalid && (transgressionForm.get('offence')?.touched || transgressionForm.get('offence')?.dirty)"
            class="px-1 py-1"
          >
            <small class="p-error block"><strong>Offence</strong> is required</small>
          </div>
        </div>
        <div class="field mb-4 col-12 lg:col-6">
          <label for="transgressionPenalty">Offence Penalty</label>
          <input
            formControlName="offence_penalty"
            type="text"
            class="form-control"
            id="transgressionPenalty"
            placeholder="Enter offence penalty number (1 - 4)"
            pInputTextarea
            rows="5"
            cols="30"
          />
          <div
            *ngIf="transgressionForm.get('offence_penalty')?.invalid && (transgressionForm.get('offence_penalty')?.touched || transgressionForm.get('offence_penalty')?.dirty)"
            class="px-1 py-1"
          >
          <small class="p-error block"><strong>Offence Penalty</strong> is required and must be a number</small>
        </div>

      </div>
      <div
      class="grid formgrid p-fluid flex align-items-center justify-content-center m-2"
    >
      <p-button styleClass="bg-yellow-500" type="submit">Submit</p-button>
    </div>
    </div>
  </div>
</form>
</ng-template>