import { Injectable, Input } from "@angular/core";
import {
  BehaviorSubject,
  Observable,
  Subject,
  map,
  of,
  switchMap,
  tap,
} from "rxjs";
import { Employee } from "../_models/employee";
import { ApiService } from "src/app/_core/_services/api.service";
import { HttpClient } from "@angular/common/http";

@Injectable({
  providedIn: "root",
})
export class EmployeeService {
  // Properties
  // ----------

  //not have in service and only return in component
  private employees: Employee[] = [];
  private employees$ = new BehaviorSubject<Employee[]>([]);

  constructor(public api: ApiService, private http: HttpClient) {}

  xgetEmployees(): Observable<Employee[]> {
    return this.api
      .getAPI<Employee[]>("employees/get")
      .pipe(map((res) => res.body.data));
  }
  getEmployees(): Observable<Employee[]> {
    return this.api.getAPI<Employee[]>("employees/get").pipe(
      map((res) =>
        res.body.data.sort(
          (a: { id: number }, b: { id: number }) => a.id - b.id
        )
      ),
      tap((employees) => this.employees$.next(employees))
    );
  }

  WaddEmployee(newEmployee: Employee): Observable<Employee> {
    return this.api.postAPI<Employee>("employees/create", newEmployee).pipe(
      map((res) => res.body),
      tap(() => this.refreshEmployees())
    );
  }
  // more like update
  addEmployee(newEmployee: Employee): Observable<any> {
    return this.api.postAPI<Employee>("employees/create", newEmployee).pipe(
      tap((res: any) => {
        console.log("Employee added", res);
      }),
      switchMap(() => this.getEmployees())
    );
  }
  // addEmployee(newEmployee: Employee): Observable<Employee[]> {
  //   return this.api.postAPI<Employee>("employees/create", newEmployee).pipe(
  //     tap((res: any) => {
  //       console.log("Employee added", res);
  //     }),
  //     switchMap(() => this.getEmployees())
  //   );
  // }

  // update and del

  updateEmployee(employeeId: number, employeeData: Employee): Observable<any> {
    return this.api
      .patchAPI<Employee>("employees/update", employeeId, employeeData)
      .pipe(
        tap((res: any) => {
          console.log("Employee updated", res);
        }),
        switchMap(() => this.getEmployees())
      );
  }
  deleteEmployee(employeeId: number): Observable<any> {
    return this.api
      .deleteAPI<any>("employees/delete", employeeId)
      .pipe(tap(() => this.refreshEmployees()));
  }

  // refreshEmployees(): void {
  //   this.getEmployees().subscribe({
  //     next: (employees) => {
  //       this.employees = employees;
  //       this.employees$.next(this.employees);
  //     },
  //     error: (err) => this.handleError("refreshEmployees", err),
  //   });
  // }
  refreshEmployees(): Observable<Employee[]> {
    return this.getEmployees().pipe(
      tap((employees) => {
        //this.employees = employees;
        this.employees$.next(employees);
      })
    );
  }
  get employeesObservable$(): Observable<Employee[]> {
    return this.employees$.asObservable();
  }
  private handleError<T>(
    operation = "operation",
    error: any,
    result?: T
  ): Observable<T> {
    console.error(`${operation} failed: ${error.message}`);
    return of(result as T);
  }
}
