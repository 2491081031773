<p-confirmDialog></p-confirmDialog>
<p-toast></p-toast>
<div class="flex flex-column flex-auto">
  <div class="grid">
    <div class="col-12">
      <div class="surface-card shadow-2 flex p-3 flex-column md:flex-row">
        <div class="flex align-items-center">
          <i class="pi pi-user-minus text-900 text-2xl mr-2"></i>
          <span class="text-900 font-medium text-2xl">Schedule Hearings</span>
        </div>
        <div class="flex flex-1 justify-content-end align-items-center"></div>
      </div>
    </div>
    <!-- <div class="col-12 lg:col-4">
      <div class="surface-card shadow-2 flex p-3 flex-column lg:flex-row">
        <button
          pButton
          class="bg-yellow-500"
          icon="pi pi-plus"
          label="Add Hearing"
          (click)="showAdd()"
        ></button>
      </div>
    </div> -->
    <div class="col-12">
      <ng-template #loadedContent>
        <div class="surface-card shadow-2 flex p-3 flex-column">
          <p-table
            [value]="hearings"
            [tableStyle]="{ 'min-width': '100%' }"
            [rowHover]="true"
            [responsiveLayout]="'scroll'"
            scrollHeight="75vh"
          >
            <ng-template pTemplate="header">
              <tr>
                <th *ngFor="let col of columns">
                  <span class="font-semibold text-md text-color-secondary">{{
                    col.header
                  }}</span>
                </th>
                <th></th>
              </tr>
            </ng-template>
            <ng-template pTemplate="body" let-hearing>
              <tr style="cursor: pointer" (click)="viewHearing(hearing)">
                <td *ngFor="let col of columns">
                  <div class="flex align-items-center gap-3">
                    <span *ngIf="col.field === 'incident_id'">
                      {{ hearing.incidentDetails }}
                    </span>
                    <span *ngIf="col.field === 'venue'">
                      {{ hearing[col.field] || "loading" }}
                    </span>
                    <span *ngIf="col.field === 'hearing_date'">
                      {{ hearing[col.field] | date : "medium" }}
                    </span>
                    <span *ngIf="col.field === 'hearing_status'">
                      <p-tag
                        [severity]="
                          getHearingStatusSeverity(hearing[col.field])
                        "
                      >
                        {{ hearing[col.field] | uppercase }}
                      </p-tag>
                    </span>
                    <!-- Add a default case to handle undefined fields -->
                    <span
                      *ngIf="
                        ![
                          'incident_id',
                          'venue',
                          'hearing_date',
                          'hearing_status'
                        ].includes(col.field)
                      "
                    >
                      {{ hearing[col.field] }}
                    </span>
                  </div>
                </td>
                <td>
                  <button
                    type="button"
                    pButton
                    icon="pi pi-pencil"
                    class="p-button-text p-button-success"
                    (click)="editHearing(hearing); $event.stopPropagation()"
                  ></button>
                </td>
              </tr>
            </ng-template>
          </p-table>
        </div>
      </ng-template>
    </div>
  </div>
  <div *ngIf="Incidents$ | async; else loadedContent">
    <div class="surface-card shadow-2 flex p-3 flex-column">
      <div class="formgrid p-fluid px-4 py-5 md:px-6 lg:px-8">
        <div class="field col-12">
          <p-skeleton width="100%" height="20px" styleClass="my-2"></p-skeleton>
          <p-skeleton width="100%" height="20px" styleClass="my-2"></p-skeleton>
          <p-skeleton width="100%" height="80px" styleClass="py-4"></p-skeleton>
        </div>
        <div class="field col-12">
          <p-skeleton width="100%" height="20px" styleClass="my-2"></p-skeleton>
          <p-skeleton width="100%" height="20px" styleClass="my-2"></p-skeleton>
          <p-skeleton width="100%" height="80px" styleClass="py-4"></p-skeleton>
        </div>
        <div class="field col-12">
          <p-skeleton width="100%" height="20px" styleClass="my-2"></p-skeleton>
          <p-skeleton width="100%" height="20px" styleClass="my-2"></p-skeleton>
          <p-skeleton width="100%" height="80px" styleClass="py-4"></p-skeleton>
        </div>
        <div class="field col-12">
          <p-skeleton width="100%" height="20px" styleClass="my-2"></p-skeleton>
          <p-skeleton width="100%" height="20px" styleClass="my-2"></p-skeleton>
        </div>
      </div>
    </div>
  </div>
</div>
