import { Injectable, Input } from "@angular/core";
import {
  BehaviorSubject,
  Observable,
  Subject,
  map,
  switchMap,
  tap,
} from "rxjs";
import { Incident } from "../_models/incident";
import { ApiService } from "src/app/_core/_services/api.service";
import { IncidentFile } from "../_models/IncidentFile";

@Injectable({
  providedIn: "root",
})
export class IncidentService {
  private incidents$ = new BehaviorSubject<Incident[]>([]);
  private incidents: Incident[] = [];

  constructor(public api: ApiService) {}

  xsgetIncidents(): Observable<Incident[]> {
    return this.api
      .getAPI<Incident[]>("incidents/get")
      .pipe(map((res) => res.body.data));
  }
  //WORKS FOR BASE INCIDENTS
  // getIncidents(): Observable<Incident[]> {
  //   return this.api.getAPI<Incident[]>("incidents/get").pipe(
  //     map((res) => res.body.data),
  //     tap((incidents) => this.incidents$.next(incidents))
  //   );
  // }
  getIncidents(): Observable<Incident[]> {
    return this.api.getAPI<Incident[]>("incidents").pipe(
      map((res) =>
        res.body.sort((a: { id: number }, b: { id: number }) => a.id - b.id)
      ),
      tap((incidents) => this.incidents$.next(incidents))
    );
  }

  //was normal add from crud
  xaddIncident(newincident: Incident): Observable<any> {
    return this.api.postAPI<Incident>("incidents/create", newincident).pipe(
      tap((res: any) => {
        console.log("Employee added", res);
      }),
      switchMap(() => this.refreshIncidents())
    );
  }
  //CUSTOM
  //renaming just for ease
  //addIncidentWithFiles
  addIncident(newIncident: Incident): Observable<any> {
    return this.api.postAPI<Incident>("incidents", newIncident).pipe(
      tap((res: any) => {
        console.log("Incident with files added", res);
      }),
      switchMap(() => this.refreshIncidents())
    );
  }

  updateIncident(IncidentId: number, incidentData: Incident): Observable<any> {
    return this.api
      .patchAPI<Incident>("incidents", IncidentId, incidentData)
      .pipe(
        tap((res: any) => {
          console.log("incident updated", res);
        }),
        switchMap(() => this.refreshIncidents())
      );
  }

  // updateIncident(IncidentId: number, incidentData: Incident): Observable<any> {
  //   return this.api
  //     .patchAPI<Incident>(
  //       `incidents/update/${IncidentId}`,
  //       IncidentId,
  //       incidentData
  //     )
  //     .pipe(
  //       tap((res: any) => {
  //         console.log("Incident updated", res);
  //       }),
  //       switchMap(() => this.refreshIncidents())
  //     );
  // }

  deleteIncident(IncidentId: number): Observable<any> {
    return this.api
      .deleteAPI<any>("incidents", IncidentId)
      .pipe(tap(() => this.refreshIncidents()));
  }

  refreshIncidents(): Observable<Incident[]> {
    return this.getIncidents().pipe(
      tap((incidents) => {
        //this.employees = employees;
        this.incidents$.next(incidents);
      })
    );
  }
  get incidentsObservable$(): Observable<Incident[]> {
    return this.incidents$.asObservable();
  }
}
