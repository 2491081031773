import { Component, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { DynamicDialogConfig, DynamicDialogRef } from "primeng/dynamicdialog";
import { BehaviorSubject } from "rxjs";
import { Incident } from "src/app/_share/_models/incident";
import { IncidentService } from "src/app/_share/_services/incident.service";
import { EmployeeService } from "src/app/_share/_services/employee.service";
import { TransgressionService } from "src/app/_share/_services/transgression.service";
import { FilesService } from "src/app/_share/_services/files.service";

@Component({
  selector: "app-create-incident",
  templateUrl: "./create-incident.component.html",
  styleUrls: ["./create-incident.component.scss"],
})
export class CreateIncidentComponent implements OnInit {
  createIncidents$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
    true
  );

  incidentForm: FormGroup = this.formBuilder.group({
    category: ["", Validators.required],
    description: ["", Validators.required],
    action_taken: ["open incident", { disabled: true }], // Disabled and with default value
    date: ["", Validators.required],
    resolution: ["", Validators.required],
    employee_id: [null, [Validators.required, Validators.pattern("^[0-9]*$")]],
    transgression_id: [
      "",
      [Validators.required, Validators.pattern("^[0-9]*$")],
    ],
  });

  editFormData: Incident | undefined;
  isInEditMode: boolean = false;
  employees: any[] = [];
  transgressions: any[] = [];
  resolutionOptions: any[];

  uploadedFiles: any[] = [];

  constructor(
    public ref: DynamicDialogRef,
    public config: DynamicDialogConfig,
    public incidentService: IncidentService,
    public employeeService: EmployeeService,
    public transgressionService: TransgressionService,
    public formBuilder: FormBuilder,
    public filesService: FilesService
  ) {
    this.resolutionOptions = [
      { label: "True", value: true },
      { label: "False", value: false },
    ];
  }

  ngOnInit(): void {
    setTimeout(() => {
      this.createIncidents$.next(false);
    }, 1000);
    this.updateFormData();
    this.loadEmployees();
    this.loadTransgressions();
    // Update category when transgression changes
    this.incidentForm
      .get("transgression_id")
      ?.valueChanges.subscribe((value) => {
        this.updateCategory(value);
      });
  }

  loadEmployees() {
    this.employeeService.getEmployees().subscribe((employees) => {
      this.employees = employees.map((employee: any) => ({
        label: `${employee.employee_code} ${employee.first_name} ${employee.last_name}`,
        value: employee.id,
      }));
    });
  }

  loadTransgressions() {
    this.transgressionService
      .getTransgressions()
      .subscribe((transgressions) => {
        this.transgressions = transgressions.map((transgression: any) => ({
          label: `${transgression.category} ${transgression.offence}`,
          value: transgression.id,
          category: transgression.category,
        }));
      });
  }

  updateCategory(transgressionId: number) {
    const selectedTransgression = this.transgressions.find(
      (transgression) => transgression.value === transgressionId
    );
    if (selectedTransgression) {
      this.incidentForm.patchValue({
        category: selectedTransgression.category,
      });
    }
  }

  // Updates the form data if in edit mode
  updateFormData() {
    this.editFormData = this.config.data?.formData;
    if (this.editFormData) {
      this.incidentForm.patchValue(this.editFormData);
      this.isInEditMode = true;
    } else {
      this.isInEditMode = false;
    }
  }

  uploadedFile: any = null;
  selectedFile: File | null = null;
  // onFileSelect(event$: any) {
  //   this.selectedFile = event$.files[0];
  // }

  onFileSelect(event$: any) {
    this.selectedFile = event$.files[0];
    this.uploadedFile = 1;
  }

  //not implemented
  handleFileData(file: File) {
    const reader = new FileReader();
    reader.onload = (e: any) => {
      console.log(e.target.result);
    };
    reader.readAsDataURL(file);
  }

  onUpload(event$: any) {
    // get rid of the files in the files section after uploading
    this.uploadedFile = 0;
  }

  // xonFileUpload($event: any) {
  //   for (let file of $event.files) {
  //     this.uploadedFiles.push(file);

  //     let formData = new FormData();
  //     formData.append("file", file);

  //     this.filesService.uploadFile(formData).subscribe({
  //       next: (response: any) => {
  //         console.log("File uploaded successfully", response);
  //       },
  //       error: (err: any) => {
  //         console.error("Error uploading file", err);
  //       },
  //     });
  //   }
  // }
  onFileUpload(event$: any) {
    for (let file of event$.files) {
      let formData = new FormData();
      formData.append("file", file);

      this.filesService.uploadFile(formData).subscribe({
        next: (response: any) => {
          console.log("File uploaded successfully", response);
          // Store uploaded file data for later use
          const uploadedFileData = response.body.data; // Capture the response data
          this.uploadedFiles.push(uploadedFileData);
        },
        error: (err: any) => {
          console.error("Error uploading file", err);
        },
      });
    }
  }

  onAddIncidentFormSubmit() {
    if (this.incidentForm.invalid) {
      this.incidentForm.markAllAsTouched();
      return;
    }

    const incidentData: Incident = {
      ...this.incidentForm.value,
      files: this.uploadedFiles, // Add the uploaded files to the incident data
    };

    if (this.isInEditMode && this.editFormData?.id !== undefined) {
      this.incidentService
        //this.incidentForm.value
        .updateIncident(this.editFormData.id, incidentData)
        .subscribe({
          next: (res: any) => {
            console.log("Incident updated successfully", res);
            this.ref.close(this.incidentForm.value);
          },
          error: (err: any) => {
            console.error("Error updating incident", err);
          },
        });
    } else {
      //this.incidentForm.value
      this.incidentService.addIncident(incidentData).subscribe({
        next: (res: any) => {
          console.log("Incident added successfully", res);
          this.incidentService.refreshIncidents();
          this.ref.close(this.incidentForm.value);
        },
        error: (err: any) => {
          console.error("Error adding incident", err);
        },
      });
    }
  }
}
