<div
  *ngIf="this.createIncidents$ | async; else loadedContent"
  class="formgrid p-fluid px-4 py-5 md:px-6 lg:px-8"
>
  <!-- Skeleton loader or loading state -->
  <div class="field col-12">
    <p-skeleton width="100%" height="20px" styleClass="my-2"></p-skeleton>
    <p-skeleton width="100%" height="20px" styleClass="my-2"></p-skeleton>
    <p-skeleton width="100%" height="80px" styleClass="py-4"></p-skeleton>
  </div>
  <div class="field col-12">
    <p-skeleton width="100%" height="20px" styleClass="my-2"></p-skeleton>
    <p-skeleton width="100%" height="20px" styleClass="my-2"></p-skeleton>
    <p-skeleton width="100%" height="80px" styleClass="py-4"></p-skeleton>
  </div>
  <div class="field col-12">
    <p-skeleton width="100%" height="20px" styleClass="my-2"></p-skeleton>
    <p-skeleton width="100%" height="20px" styleClass="my-2"></p-skeleton>
  </div>
</div>

<ng-template #loadedContent>
  <form [formGroup]="incidentForm" (ngSubmit)="onAddIncidentFormSubmit()">
    <div class="surface-section px-4 py-4 md:px-6 lg:px-8">
      <div class="grid formgrid p-fluid">
        <div class="field mb-4 col-12 lg:col-6">
          <label for="employee_id">Employee</label>
          <p-dropdown
            [options]="employees"
            optionLabel="label"
            optionValue="value"
            formControlName="employee_id"
            placeholder="Select an employee"
            [filter]="true"
            filterPlaceholder="Search employees by code or name"
          ></p-dropdown>
          <div
            *ngIf="
              incidentForm.controls['employee_id'].invalid &&
              (incidentForm.controls['employee_id'].dirty ||
                incidentForm.controls['employee_id'].touched)
            "
            class="p-error"
          >
            Employee is required.
          </div>
        </div>
        <!-- Transgression Dropdown -->
        <div class="field mb-4 col-12 lg:col-6">
          <label for="transgression_id">Transgression</label>
          <p-dropdown
            [options]="transgressions"
            optionLabel="label"
            optionValue="value"
            formControlName="transgression_id"
            placeholder="Select a transgression"
            styleClass="custom-dropdown"
            [filter]="true"
            filterPlaceholder="Search transgressions by category or starting word"
          ></p-dropdown>
          <div
            *ngIf="
              incidentForm.controls['transgression_id'].invalid &&
              (incidentForm.controls['transgression_id'].dirty ||
                incidentForm.controls['transgression_id'].touched)
            "
            class="p-error"
          >
            Transgression is required.
          </div>
        </div>
        <div class="field mb-4 col-12 lg:col-6">
          <label for="incidentCategory">Category</label>
          <!---
          <input
            formControlName="category"
            type="text"
            class="form-control"
            id="incidentCategory"
            placeholder="Enter category"
            pInputTextarea
            rows="5"
            cols="30"
          />
        -->
          <input
            formControlName="category"
            type="text"
            class="form-control"
            id="incidentCategory"
            placeholder="category provided by transgression"
            pInputTextarea
            rows="5"
            cols="30"
            readonly
          />
          <div
            *ngIf="
              incidentForm.get('category')?.invalid &&
              (incidentForm.get('category')?.touched ||
                incidentForm.get('category')?.dirty)
            "
            class="px-1 py-1"
          >
            <small class="p-error block"
              ><strong>Category</strong> is required</small
            >
          </div>
        </div>
        <div class="field mb-4 col-12 lg:col-6">
          <label for="incidentDescription">Description</label>
          <input
            formControlName="description"
            type="text"
            class="form-control"
            id="incidentDescription"
            placeholder="Enter description"
            pInputTextarea
            rows="5"
            cols="30"
          />
          <div
            *ngIf="
              incidentForm.get('description')?.invalid &&
              (incidentForm.get('description')?.touched ||
                incidentForm.get('description')?.dirty)
            "
            class="px-1 py-1"
          >
            <small class="p-error block"
              ><strong>Description</strong> is required</small
            >
          </div>
        </div>
        <div class="field mb-4 col-12 lg:col-6">
          <label for="incidentActionTaken">Action Taken</label>
          <input
            formControlName="action_taken"
            type="text"
            class="form-control"
            id="incidentActionTaken"
            placeholder="Enter action taken"
            pInputTextarea
            rows="5"
            cols="30"
            readonly
          />
          <!-- <div
            *ngIf="
              incidentForm.get('action_taken')?.invalid &&
              (incidentForm.get('action_taken')?.touched ||
                incidentForm.get('action_taken')?.dirty)
            "
            class="px-1 py-1"
          >
            <small class="p-error block"
              ><strong>Action Taken</strong> is required</small
            >
          </div> -->
        </div>
        <div class="field mb-4 col-12 lg:col-6">
          <label for="incidentDate">Date</label>
          <p-calendar
            formControlName="date"
            dateFormat="yy-mm-dd"
            dataType="string"
            [inline]="false"
            [touchUI]="true"
            id="incidentDate"
            rows="5"
            cols="30"
          ></p-calendar>
        </div>
        <!--
        <div class="field mb-4 col-12 lg:col-6">
          <label for="incidentDate">Date</label>
          <input
            formControlName="date"
            type="text"
            class="form-control"
            id="incidentDate"
            placeholder="Enter date"
            pInputTextarea
            rows="5"
            cols="30"
          />
          <div
            *ngIf="
              incidentForm.get('date')?.invalid &&
              (incidentForm.get('date')?.touched ||
                incidentForm.get('date')?.dirty)
            "
            class="px-1 py-1"
          >
            <small class="p-error block"
              ><strong>Date</strong> is required</small
            >
          </div>
        </div>
        -->
        <div class="field mb-4 col-12 lg:col-6">
          <label for="incidentResolution">Resolution</label>
          <!--
          <input
            formControlName="resolution"
            type="text"
            class="form-control"
            id="incidentResolution"
            placeholder="Enter resolution"
            pInputTextarea
            rows="5"
            cols="30"
          />
          --->
          <p-dropdown
            formControlName="resolution"
            [options]="resolutionOptions"
            optionLabel="label"
            optionValue="value"
            placeholder="Select a Resolution"
          ></p-dropdown>
          <div
            *ngIf="
              incidentForm.get('resolution')?.invalid &&
              (incidentForm.get('resolution')?.touched ||
                incidentForm.get('resolution')?.dirty)
            "
            class="px-1 py-1"
          >
            <small class="p-error block"
              ><strong>Resolution</strong> is required</small
            >
          </div>
        </div>
        <!---
        <div class="field mb-4 col-12 lg:col-6">
          <label for="incidentEmployeeId">Employee ID</label>
          <input
            formControlName="employee_id"
            type="text"
            class="form-control"
            id="incidentEmployeeId"
            placeholder="Enter employee ID"
            pInputTextarea
            rows="5"
            cols="30"
          />
          <div
            *ngIf="incidentForm.get('employee_id')?.invalid && (incidentForm.get('employee_id')?.touched || incidentForm.get('employee_id')?.dirty)"
            class="px-1 py-1"
          >
            <small class="p-error block"><strong>Employee ID</strong> is required and must be a number</small>
          </div>
        </div>
        -->


        <!--
        <div class="field mb-4 col-12 lg:col-12">
          <label for="incidentTransgressionId">Transgression ID</label>
          <input
            formControlName="transgression_id"
            type="text"
            class="form-control"
            id="incidentTransgressionId"
            placeholder="Enter transgression ID"
            pInputTextarea
            rows="5"
            cols="30"
          />
          <div
            *ngIf="
              incidentForm.get('transgression_id')?.invalid &&
              (incidentForm.get('transgression_id')?.touched ||
                incidentForm.get('transgression_id')?.dirty)
            "
            class="px-1 py-1"
          >
            <small class="p-error block"
              ><strong>Transgression ID</strong> is required and must be a
              number</small
            >
          </div>
        </div>
        --->
        <div class="field mb-4 col-12 lg:col-6">
          <label for="file_id">File</label>
          <p-fileUpload
            name="files[]"
            (onUpload)="onUpload($event)"
            [multiple]="true"
            [customUpload]="true"
            accept="image/*,.pdf,.doc,.docx,.xml,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document"
            [maxFileSize]="1000000"
            (uploadHandler)="onFileUpload($event)"
          >
            <ng-template pTemplate="content">
              <ul *ngIf="uploadedFiles.length">
                <li *ngFor="let file of uploadedFiles">
                  {{ file.name }} - {{ file.size }} bytes
                </li>
              </ul>
            </ng-template>
          </p-fileUpload>
          <!-- <p-fileUpload
            mode="advanced"
            chooseLabel="Choose"
            [showUploadButton]="false"
            accept="image/*"
            [auto]="false"
            accept=".pdf,.doc,.docx,.xml,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document"
            [customUpload]="true"
            [multiple]="true"
            (onSelect)="onFileSelect($event)"
            (uploadHandler)="onFileUpload($event)"
          >
            <ng-template let-file pTemplate="file">
              <div *ngIf="uploadedFile > 0">
                {{ file.name }} -
                {{ file.size / 1024 / 1024 | number : "1.2-2" }}MB
              </div>
            </ng-template>
          </p-fileUpload> -->
        </div>
        <div
          class="grid formgrid p-fluid flex align-items-center justify-content-center m-2"
        >
          <p-button styleClass="bg-yellow-500" type="submit">Submit</p-button>
        </div>
      </div>
    </div>
  </form>
</ng-template>
