import { Component, Inject, OnInit } from "@angular/core";
import { ConfirmationService, MessageService } from "primeng/api";
import {
  DialogService,
  DynamicDialogConfig,
  DynamicDialogRef,
} from "primeng/dynamicdialog";
import { BehaviorSubject } from "rxjs";
import { MAT_DIALOG_DATA } from "@angular/material/dialog";
import { Observable } from "rxjs";
import { FilesService } from "src/app/_share/_services/files.service";
import { IncidentService } from "src/app/_share/_services/incident.service";
import { TransgressionService } from "src/app/_share/_services/transgression.service";
import { EmployeeService } from "src/app/_share/_services/employee.service";
import { ActionService } from "src/app/_share/_services/action.service";
import { Action } from "src/app/_share/_models/action";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";

@Component({
  selector: "app-view-incident",
  templateUrl: "./view-incident.component.html",
  styleUrls: ["./view-incident.component.scss"],
  providers: [ConfirmationService, MessageService],
})
export class ViewIncidentComponent implements OnInit {
  Incidents$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
  incident: any;
  // employeeName: string = "Unknown";
  // transgression: string = "Unknown";
  employees: any[] = [];
  transgressions: any[] = [];
  employeeIncidentHistory: any[] = [];
  incidentActions: Action[] = [];
  resolutionOptions: any[];

  incidentForm: FormGroup = this.formBuilder.group({
    resolution: ["", Validators.required],
  });

  constructor(
    public ref: DynamicDialogRef, // Inject DynamicDialogRef
    private config: DynamicDialogConfig,
    public employeeService: EmployeeService,
    public filesService: FilesService,
    public transgressionService: TransgressionService,
    private incidentService: IncidentService,
    private actionService: ActionService,
    public formBuilder: FormBuilder,
    public messageService: MessageService
  ) {
    this.resolutionOptions = [
      { label: "Guilty", value: true },
      { label: "Not Guilty", value: false },
    ];
  }

  ngOnInit() {
    this.incident = this.config.data.incident;
    this.loadEmployees();
    this.loadTransgressions();
    this.loadEmployeeIncidentHistory(); // Load history of incidents related to the employee
    this.loadIncidentActions(); // Load actions related to the incident
    setTimeout(() => {
      this.Incidents$.next(false); // Set loading to false after data is loaded
    }, 2000); // Adjust the timeout as necessary
  }

  loadEmployees() {
    this.employeeService.getEmployees().subscribe((employees) => {
      this.employees = employees;
    });
  }

  loadTransgressions() {
    this.transgressionService
      .getTransgressions()
      .subscribe((transgressions) => {
        this.transgressions = transgressions;
      });
  }

  getEmployeeNameById(id: number): string {
    const employee = this.employees.find((emp: any) => emp.id === id);
    return employee
      ? `${employee.employee_code} ${employee.first_name} ${employee.last_name}`
      : "loading...";
  }

  getTransgressionOffenceById(id: number): string {
    const transgression = this.transgressions.find(
      (trans: any) => trans.id === id
    );
    return transgression ? `${transgression.offence}` : "loading...";
  }

  // Method to map offence_penalty values to their respective text
  getPenaltyText(penalty: number): string {
    switch (penalty) {
      case 1:
        return "Verbal Warning";
      case 2:
        return "Written Warning";
      case 3:
        return "Final Warning";
      case 4:
        return "Termination";
      default:
        return "Unknown Penalty";
    }
  }

  getTransgressionPenaltyById(id: number): string {
    const transgression = this.transgressions.find(
      (trans: any) => trans.id === id
    );
    return transgression
      ? this.getPenaltyText(transgression.offence_penalty)
      : "loading...";
  }

  getFileLinks(files: any[]): any[] {
    if (!files || files.length === 0) {
      return [];
    }
    return files.map((file) => ({
      unique_file_reference: file.unique_file_reference,
      original_filename: file.original_filename,
    }));
  }

  downloadFile(unique_file_reference: string, original_filename: string) {
    this.filesService
      .downloadFile(unique_file_reference, original_filename)
      .subscribe(
        () => {
          console.log("File downloaded successfully");
        },
        (error) => {
          console.error("Error downloading file:", error);
        }
      );
  }
  loadEmployeeIncidentHistory() {
    if (this.incident && this.incident.employee_id) {
      this.incidentService.getIncidents().subscribe((incidents) => {
        this.employeeIncidentHistory = incidents.filter(
          (inc: any) => inc.employee_id === this.incident.employee_id
        );
      });
    }
  }
  loadIncidentActions() {
    if (this.incident && this.incident.employee_id) {
      this.actionService
        .getActionsByIncidentId(this.incident.id)
        .subscribe((incidentActions) => {
          this.incidentActions = incidentActions;
        });
    }
  }
  //old
  //XXXXXXXXXXXXXXXXXXX
  xconcludeIncident() {
    if (this.incidentForm.invalid) {
      this.incidentForm.markAllAsTouched();
      return;
    }

    const resolution = this.incidentForm.get("resolution")?.value;
    const updatedIncident = {
      ...this.incident,
      incident_status: "concluded",
      action_taken: "closed incident",
      resolution: resolution, // Include the resolution value
    };

    this.incidentService
      .updateIncident(this.incident.id, updatedIncident)
      .subscribe(() => {
        this.ref.close(updatedIncident);
      });
  }
  //XXXXXXXXXXXXXXXXXXX
  //new idea
  concludeIncident() {
    if (this.incidentForm.invalid) {
      this.incidentForm.markAllAsTouched();
      return;
    }

    const resolution = this.incidentForm.get("resolution")?.value;
    let actionTaken = this.determineActionTaken(resolution);

    const updatedIncident = {
      ...this.incident,
      incident_status: "concluded",
      action_taken: actionTaken,
      resolution: resolution,
    };

    this.incidentService
      .updateIncident(this.incident.id, updatedIncident)
      .subscribe(() => {
        this.messageService.add({
          severity: "success",
          summary: "Incident Concluded",
          detail: "The incident has been successfully concluded.",
        });
        this.ref.close(updatedIncident);
      });
  }

  private getOffencePenaltyByTransgressionId(id: number): number | null {
    const transgression = this.transgressions.find(
      (trans: any) => trans.id === id
    );
    return transgression ? transgression.offence_penalty : null;
  }

  private determineActionTaken(resolution: boolean): string {
    if (!resolution) {
      return "Not Guilty";
    }

    const offencePenalty = this.getOffencePenaltyByTransgressionId(
      this.incident.transgression_id
    );

    switch (offencePenalty) {
      case 1:
        return this.getActionForCategory1();
      case 2:
        return this.getActionForCategory2();
      case 3:
        return this.getActionForCategory3();
      case 4:
        return this.getActionForCategory4();
      default:
        return "Unknown Penalty";
    }
  }

  private getOffenceCountForCategory(category: number): number {
    return this.employeeIncidentHistory.filter(
      (incident) => incident.transgression_id === this.incident.transgression_id
    ).length;
  }

  // private getOffenceCountForCategory(categoryId: number): number {
  //   // Logic to calculate number of offences for the given category
  //   return this.employeeIncidentHistory.filter(
  //     (history) => history.category === categoryId
  //   ).length;
  // }

  private getActionForCategory1(): string {
    const offenceCount = this.getOffenceCountForCategory(1);
    if (offenceCount === 1) {
      return "Verbal Warning";
    } else if (offenceCount === 2) {
      return "Written Warning";
    } else if (offenceCount === 3) {
      return "Final Warning";
    }
    return "Notice of Hearing + Potential Termination"; // For 4th or more offences
  }

  private getActionForCategory2(): string {
    const offenceCount = this.getOffenceCountForCategory(2);
    if (offenceCount === 0) {
      return "Written Warning";
    } else if (offenceCount === 1) {
      return "Notice of Hearing + Final Warning";
    }
    return "Notice of Hearing + Potential Termination"; // For 3rd or more offences
  }

  private getActionForCategory3(): string {
    const offenceCount = this.getOffenceCountForCategory(3);
    if (offenceCount === 0) {
      return "Final Warning";
    } else if (offenceCount === 1) {
      return "Notice of Hearing + Potential Termination";
    }
    return "Notice of Hearing Potential Termination"; // For 2nd or more offences
  }

  private getActionForCategory4(): string {
    const offenceCount = this.getOffenceCountForCategory(4);
    if (offenceCount === 0) {
      return "Notice of Hearing";
    }
    return "Notice of Hearing + Potential Termination"; // For 2nd or more offences
  }
}

//   concludeIncident() {
//     if (this.incidentForm.invalid) {
//       this.incidentForm.markAllAsTouched();
//       return;
//     }

//     const resolution = this.incidentForm.get("resolution")?.value;
//     const category = this.incident.category;

//     let actionTaken = "";
//     let state = "";
//     let documentRequired = false;
//     let notificationDetail = "";

//     if (resolution) {
//       // If guilty
//       switch (category) {
//         case 1:
//           actionTaken = "verbal warning";
//           state = "VERBAL WARNING";
//           notificationDetail =
//             "Please discipline the employee with a verbal warning.";
//           break;
//         case 2:
//           actionTaken = "written warning";
//           state = "WRITTEN WARNING";
//           documentRequired = true;
//           notificationDetail =
//             "Please submit a written warning to the employee.";
//           break;
//         case 3:
//           actionTaken = "final warning";
//           state = "FINAL WARNING";
//           documentRequired = true;
//           notificationDetail = "Please submit a final warning to the employee.";
//           break;
//         case 4:
//           actionTaken = "notice of hearing";
//           state = "PENDING HEARING";
//           notificationDetail =
//             "A notice of hearing needs to be generated and sent to the employee.";
//           break;
//       }

//       // If a document is required, handle document generation and sending
//       if (documentRequired) {
//         // Code to generate and send the document
//       }

//       // Notify user
//       this.messageService.add({
//         severity: "info",
//         summary: "Action Required",
//         detail: notificationDetail,
//       });
//     } else {
//       // If not guilty
//       actionTaken = "no action";
//       state = "CONCLUDED";
//       this.messageService.add({
//         severity: "info",
//         summary: "Resolution",
//         detail:
//           "The employee was found not guilty. No further action required.",
//       });
//     }

//     const updatedIncident = {
//       ...this.incident,
//       incident_status: "closed",
//       action_taken: actionTaken,
//       resolution: resolution,
//       state: state,
//     };

//     this.incidentService
//       .updateIncident(this.incident.id, updatedIncident)
//       .subscribe(() => {
//         this.ref.close(updatedIncident); // Close the dialog and pass the updated incident as the result
//       });
//   }
// }

// checkEmployeeTransgressions() {
//   this.transgressionService.getTransgressionsByEmployeeId(this.incident.employee_id).subscribe(transgressions => {
//     const categoryCount = {
//       1: 0,
//       2: 0,
//       3: 0,
//       4: 0
//     };

//     transgressions.forEach(transgression => {
//       if (transgression.category_id >= 1 && transgression.category_id <= 4) {
//         categoryCount[transgression.category_id]++;
//       }
//     });

//     if (categoryCount[1] === 1) {
//       this.notifyUser('Please send a Verbal Warning.');
//     } else if (categoryCount[2] === 1) {
//       this.notifyUser('Please send a Written Warning.');
//     } else if (categoryCount[3] === 1) {
//       this.notifyUser('Please send a Final Warning.');
//     } else if (categoryCount[4] === 1) {
//       this.notifyUser('Please send a Termination Warning.');
//     }
//   });
// }
// }
// concludeIncident() {
//   let actionState: string = ""; // Initialize with an empty string or a default value
//   let message: string = "Incident concluded"; // Default message

//   if (this.incident.category === 1) {
//     actionState = "VERBAL_WARNING";
//     message = "Notification sent for verbal warning";
//   } else if (
//     this.incident.category === 2 ||
//     this.hasTooManyLowerCategoryOffences(2)
//   ) {
//     actionState = "WRITTEN_WARNING";
//     message = "Document and notification sent for written warning";
//   } else if (
//     this.incident.category === 3 ||
//     this.hasTooManyLowerCategoryOffences(3)
//   ) {
//     actionState = "FINAL_WARNING";
//     message = "Document and notification sent for final warning";
//   } else if (
//     this.incident.category === 4 ||
//     this.hasTooManyLowerCategoryOffences(4)
//   ) {
//     actionState = "PENDING_HEARING";
//     message = "Notification and hearing notice sent";
//   } else {
//     console.error("Unexpected incident category:", this.incident.category);
//     return; // Optionally handle an unexpected category
//   }

//   // Update the incident with the new state
//   const updatedIncident = { ...this.incident, action_taken: actionState };

//   this.incidentService
//     .updateIncident(this.incident.id, updatedIncident)
//     .subscribe(() => {
//       this.messageService.add({
//         severity: "success",
//         summary: "Success",
//         detail: message,
//       });
//       this.loadIncidentActions(); // Refresh the incident actions
//     });
// }
// triggerAction(state: string, message: string) {
//   // Update the incident status and trigger appropriate actions
//   this.incidentService.updateIncident(this.incident.id, state).subscribe(() => {
//     this.messageService.add({ severity: 'success', summary: 'Success', detail: message });
//     this.loadIncidentActions(); // Refresh the incident actions
//   });
// }

// hasTooManyLowerCategoryOffences(category: number): boolean {
//   // Logic to determine if the employee has too many lower category offences
//   const lowerCategoryOffences = this.employeeIncidentHistory.filter(
//     (history) => history.category < category
//   );
//   return lowerCategoryOffences.length > 3;
// }
