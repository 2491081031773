import { Component, OnDestroy, OnInit } from "@angular/core";
import { ConfirmationService, MessageService } from "primeng/api";
import { DialogService, DynamicDialogRef } from "primeng/dynamicdialog";
import { BehaviorSubject, Subscription } from "rxjs";
import { IncidentService } from "src/app/_share/_services/incident.service";
import { CreateIncidentComponent } from "../create-incident/create-incident.component";
import { EmployeeService } from "src/app/_share/_services/employee.service";
import { TransgressionService } from "src/app/_share/_services/transgression.service";
import { ViewIncidentComponent } from "../view-incident/view-incident.component";
import { FilesService } from "src/app/_share/_services/files.service";

@Component({
  selector: "app-incidents",
  templateUrl: "./incidents.component.html",
  styleUrls: ["./incidents.component.scss"],
  providers: [ConfirmationService, MessageService],
})
export class IncidentsComponent implements OnInit, OnDestroy {
  selectedRowCMObservable$: BehaviorSubject<any> = new BehaviorSubject<any>(
    null
  );
  Incidents$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
  ref: DynamicDialogRef | undefined;
  incidents: any[] = [];
  incidentsObservable$: any;
  private subscriptions: Subscription = new Subscription();
  employees: any[] = [];
  transgressions: any[] = [];

  constructor(
    public dialogService: DialogService,
    public incidentService: IncidentService,
    private messageService: MessageService,
    private confirmationService: ConfirmationService,
    public employeeService: EmployeeService,
    public transgressionService: TransgressionService,
    public FilesService: FilesService
  ) {}

  ngOnInit(): void {
    setTimeout(() => {
      this.Incidents$.next(false);
    }, 3000);
    this.subscriptions.add(
      this.incidentService.getIncidents().subscribe((incidents) => {
        this.incidents = incidents;
        console.log("incidents", incidents);
      })
    );
    this.loadEmployees(); // Load employees
    this.loadTransgressions(); // Load transgressions
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  loadEmployees() {
    this.employeeService.getEmployees().subscribe((employees) => {
      this.employees = employees;
    });
  }
  loadTransgressions() {
    this.transgressionService
      .getTransgressions()
      .subscribe((transgressions) => {
        this.transgressions = transgressions;
      });
  }

  getEmployeeNameById(id: number): string {
    const employee = this.employees.find((emp: any) => emp.id === id);
    return employee
      ? `${employee.employee_code}  ${employee.first_name} ${employee.last_name}`
      : "loading...";
  }

  getTransgressionOffenceById(id: number): string {
    const transgression = this.transgressions.find(
      (trans: any) => trans.id === id
    );
    return transgression ? `${transgression.offence}` : "loading...";
  }

  getIncidentsOptionsList() {
    this.subscriptions.add(
      this.incidentService.getIncidents().subscribe((incidents: any[]) => {
        this.incidents = incidents;
      })
    );
  }

  getIncidentStatusSeverity(value: string): string {
    switch (value) {
      case "open":
        return "success";
      case "concluded":
        return "warning";
      // case "Unresolved":
      // return "danger";
      default:
        return "info";
    }
  }

  columns = [
    { field: "incident_status", header: "Status" },
    { field: "category", header: "Category" },
    { field: "description", header: "Description" },
    { field: "action_taken", header: "Action Taken" },
    { field: "date", header: "Date" },
    { field: "resolution", header: "Resolution" },
    { field: "employee_id", header: "Employee" },
    { field: "transgression_id", header: "Transgression" },
    { field: "files", header: "Files" }, // New column
  ];

  getFileLinks(files: any[]): any[] {
    if (!files || files.length === 0) {
      return [];
    }
    return files.map((file) => ({
      unique_file_reference: file.unique_file_reference,
      original_filename: file.original_filename,
    }));
  }

  downloadFile(unique_file_reference: string, original_filename: string) {
    this.FilesService.downloadFile(
      unique_file_reference,
      original_filename
    ).subscribe(
      () => {
        console.log("File downloaded successfully");
      },
      (error) => {
        console.error("Error downloading file:", error);
      }
    );
  }
  isLastElement(file: any, filesArray: any[]): boolean {
    return filesArray.indexOf(file) === filesArray.length - 1;
  }

  viewIncident(rowDatas: any) {
    this.ref = this.dialogService.open(ViewIncidentComponent, {
      data: {
        incident: rowDatas,
      },
      header: "Incident Details",
      width: "65%",
      height: "70%",
      closeOnEscape: true,
    });

    this.ref.onClose.subscribe((result) => {
      if (result) {
        this.getIncidentsOptionsList();
        this.messageService.add({
          severity: "success",
          summary: "Success",
          detail: "Incident successfully concluded",
        });
      }
    });
  }

  showAdd() {
    this.ref = this.dialogService.open(CreateIncidentComponent, {
      data: {
        incidentData: null,
        listOfIncidents: this.incidentService.getIncidents(),
      },
      header: "Add Incident",
      width: "65%",
      height: "70%",
      closeOnEscape: true,
    });

    this.ref.onClose.subscribe((result) => {
      if (result) {
        this.getIncidentsOptionsList();
        this.messageService.add({
          severity: "success",
          summary: "Success",
          detail: "Incident successfully added",
        });
      }
    });
  }

  editIncident(rowData: any) {
    this.ref = this.dialogService.open(CreateIncidentComponent, {
      data: {
        formData: rowData,
      },
      header: "Edit Incident",
      width: "65%",
      height: "70%",
      closeOnEscape: true,
    });

    this.ref.onClose.subscribe((result) => {
      if (result) {
        console.log("rowdata");
        console.log(rowData);
        const incidentId = rowData.id;
        this.incidentService
          .updateIncident(incidentId, result)
          .subscribe(() => {
            this.getIncidentsOptionsList();
            this.messageService.add({
              severity: "success",
              summary: "Success",
              detail: "Incident updated successfully",
            });
          });
      }
    });

    // this.ref.onClose.subscribe((result) => {
    //   if (result) {
    //     this.getIncidentsOptionsList();
    //     this.messageService.add({
    //       severity: "success",
    //       summary: "Success",
    //       detail: "Incident updated successfully",
    //     });
    //   }
    // });
  }

  deleteIncident(rowData: any) {
    this.confirmationService.confirm({
      message: "Are you sure you want to delete this incident?",
      header: "Delete Confirmation",
      icon: "pi pi-info-circle",
      accept: () => {
        this.incidentService.deleteIncident(rowData.id).subscribe(() => {
          this.getIncidentsOptionsList();
          this.messageService.add({
            severity: "success",
            summary: "Success",
            detail: "Incident deleted successfully",
          });
        });
      },
      reject: () => {
        this.messageService.add({
          severity: "info",
          summary: "Rejected",
          detail: "Incident was not deleted",
        });
      },
    });
  }
}
