<p-confirmDialog></p-confirmDialog>
<p-toast></p-toast>
<div class="flex flex-column flex-auto">
  <div class="grid">
    <div class="col-12">
      <div class="surface-card shadow-2 flex p-3 flex-column md:flex-row">
        <div class="flex align-items-center">
          <i
            class="pi pi-fw pi-exclamation-triangle text-900 text-2xl mr-2"
          ></i>
          <span class="text-900 font-medium text-2xl">Transgressions</span>
        </div>
        <div class="flex flex-1 justify-content-end align-items-center"></div>
      </div>
    </div>
    <div class="col-12 lg:col-4">
      <div class="surface-card shadow-2 flex p-3 flex-column lg:flex-row">
        <button
          pButton
          class="bg-yellow-500"
          icon="pi pi-plus"
          label="Add Transgression"
          (click)="showAdd()"
        ></button>
      </div>
    </div>
    <div class="col-12">
      <ng-template #loadedContent>
        <div class="surface-card shadow-2 flex p-3 flex-column">
          <p-table
            [value]="transgressions"
            [tableStyle]="{ 'min-width': '100%' }"
            [rowHover]="true"
            [responsiveLayout]="'scroll'"
            scrollHeight="75vh"
          >
            <ng-template pTemplate="header">
              <tr>
                <th *ngFor="let col of columns">
                  <span class="font-semibold text-md text-color-secondary">{{
                    col.header
                  }}</span>
                </th>
                <th></th>
              </tr>
            </ng-template>
            <ng-template pTemplate="body" let-transgression>
              <tr style="cursor: pointer">
                <td *ngFor="let col of columns">
                  <div class="flex align-items-center gap-3">
                    <div>
                      <p
                        class="mt- mb-2 font-medium text-md text-color-primary"
                      >
                        {{
                          col.field === "offence_penalty"
                            ? getPenaltyText(transgression[col.field])
                            : transgression[col.field]
                        }}
                      </p>
                    </div>
                  </div>
                </td>
                <td>
                  <button
                    type="button"
                    pButton
                    icon="pi pi-pencil"
                    class="p-button-text p-button-success"
                    (click)="editTransgression(transgression)"
                  ></button>

                  <button
                    type="button"
                    pButton
                    icon="pi pi-trash"
                    class="p-button-text p-button-danger"
                    (click)="deleteTransgression(transgression)"
                  ></button>
                </td>
              </tr>
            </ng-template>
          </p-table>
        </div>
      </ng-template>
    </div>
  </div>
  <div *ngIf="this.Transgressions$ | async; else loadedContent">
    <div class="surface-card shadow-2 flex p-3 flex-column">
      <div class="formgrid p-fluid px-4 py-5 md:px-6 lg:px-8">
        <div class="field col-12">
          <p-skeleton width="100%" height="20px" styleClass="my-2"></p-skeleton>
          <p-skeleton width="100%" height="20px" styleClass="my-2"></p-skeleton>
          <p-skeleton width="100%" height="80px" styleClass="py-4"></p-skeleton>
        </div>
        <div class="field col-12">
          <p-skeleton width="100%" height="20px" styleClass="my-2"></p-skeleton>
          <p-skeleton width="100%" height="20px" styleClass="my-2"></p-skeleton>
          <p-skeleton width="100%" height="80px" styleClass="py-4"></p-skeleton>
        </div>
        <div class="field col-12">
          <p-skeleton width="100%" height="20px" styleClass="my-2"></p-skeleton>
          <p-skeleton width="100%" height="20px" styleClass="my-2"></p-skeleton>
          <p-skeleton width="100%" height="80px" styleClass="py-4"></p-skeleton>
        </div>
        <div class="field col-12">
          <p-skeleton width="100%" height="20px" styleClass="my-2"></p-skeleton>
          <p-skeleton width="100%" height="20px" styleClass="my-2"></p-skeleton>
          
        </div>

      </div>
    </div>
  </div>
</div>
