import { Component, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { DynamicDialogConfig, DynamicDialogRef } from "primeng/dynamicdialog";
import { BehaviorSubject } from "rxjs";
import { Transgression } from "src/app/_share/_models/transgression";
import { TransgressionService } from "src/app/_share/_services/transgression.service";

@Component({
  selector: "app-create-transgression",
  templateUrl: "./create-transgression.component.html",
  styleUrls: ["./create-transgression.component.scss"],
})
export class CreateTransgressionComponent implements OnInit {
  createTransgressions$: BehaviorSubject<boolean> =
    new BehaviorSubject<boolean>(true);

  transgressionForm: FormGroup = this.formBuilder.group({
    category: ["", Validators.required],
    standard: ["", Validators.required],
    offence: ["", Validators.required],
    offence_penalty: [
      "",
      [Validators.required, Validators.pattern("^[0-9]*$")],
    ],
  });

  editFormData: Transgression | undefined;
  isInEditMode: boolean = false;
  loading: boolean = false;
  constructor(
    public ref: DynamicDialogRef,
    public config: DynamicDialogConfig,
    public transgressionService: TransgressionService,
    public formBuilder: FormBuilder
  ) {}

  ngOnInit(): void {
    // Simulate loading completion
    setTimeout(() => {
      this.createTransgressions$.next(false);
    }, 1000);
    this.updateFormData();
  }

  // Updates the form data if in edit mode
  updateFormData() {
    this.editFormData = this.config.data?.formdata;
    if (this.editFormData) {
      this.transgressionForm.patchValue(this.editFormData);
      this.isInEditMode = true;
    } else {
      this.isInEditMode = false;
    }
  }

  onAddTransgressionFormSubmit() {
    this.loading = true;
    if (this.transgressionForm.invalid) {
      this.transgressionForm.markAllAsTouched();
      return;
    }
    if (this.isInEditMode && this.editFormData?.id !== undefined) {
      this.transgressionService
        .updateTransgression(this.editFormData.id, this.transgressionForm.value)
        .subscribe({
          next: (res: any) => {
            console.log("Transgression updated successfully", res);
            this.ref.close(this.transgressionForm.value);
          },
          error: (err: any) => {
            console.error("Error updating transgression", err);
          },
        });
    } else {
      this.transgressionService
        .addTransgression(this.transgressionForm.value)
        .subscribe({
          next: (res: any) => {
            console.log("Transgression added successfully", res);
            this.transgressionService.refreshTransgressions();
            this.ref.close(this.transgressionForm.value);
          },
          error: (err: any) => {
            console.error("Error adding transgression", err);
          },
        });
    }
  }
}
