import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable, throwError } from "rxjs";
import { ApiService } from "src/app/_core/_services/api.service";
import { catchError, tap } from "rxjs/operators"; // Import tap operator if not already imported

@Injectable({
  providedIn: "root",
})
export class FilesService {
  private filesSubject = new BehaviorSubject<any[]>([]);
  files$ = this.filesSubject.asObservable();

  constructor(private api: ApiService) {}

  uploadFile(formData: FormData): Observable<any> {
    // Return the observable from postAPI
    return this.api.postFileAPI("files/upload-file", formData).pipe(
      tap((response: any) => {
        console.log("response", response.body);
        this.filesSubject.next(response.body);
      })
    );
  }

  getFiles(): Observable<any> {
    return this.api.getAPI("files/get-files");
  }

  downloadFile(
    unique_file_reference: string,
    original_filename: string
  ): Observable<any> {
    return this.api
      .getFileAPI(`files/download-file/${unique_file_reference}`, "")
      .pipe(
        tap((response: any) => {
          const blob: Blob = response.body as Blob;
          const a = document.createElement("a");

          a.download = original_filename || `${unique_file_reference}`;
          a.href = window.URL.createObjectURL(blob);
          a.click();

          window.URL.revokeObjectURL(a.href); // Clean up
        }),
        catchError((error) => {
          console.error("Download error:", error);
          return throwError(error);
        })
      );
  }

  get_user_files(user_id: string): Observable<any> {
    return this.api.getAPI(`files/get-files/${user_id}`);
  }
}
