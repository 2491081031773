import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { ButtonModule } from "primeng/button";
import { CalendarModule } from "primeng/calendar";
import { CardModule } from "primeng/card";
import { ChartModule } from "primeng/chart";
import { CheckboxModule } from "primeng/checkbox";
import { ChipModule } from "primeng/chip";
import { ConfirmDialogModule } from "primeng/confirmdialog";
import { ContextMenuModule } from "primeng/contextmenu";
import { DialogModule } from "primeng/dialog";
import { DividerModule } from "primeng/divider";
import { DropdownModule } from "primeng/dropdown";
import { FileUploadModule } from "primeng/fileupload";
import { InputMaskModule } from "primeng/inputmask";
import { InputNumberModule } from "primeng/inputnumber";
import { InputSwitchModule } from "primeng/inputswitch";
import { InputTextModule } from "primeng/inputtext";
import { InputTextareaModule } from "primeng/inputtextarea";
import { MenuModule } from "primeng/menu";
import { MenubarModule } from "primeng/menubar";
import { MessagesModule } from "primeng/messages";
import { MultiSelectModule } from "primeng/multiselect";
import { PanelMenuModule } from "primeng/panelmenu";
import { ProgressSpinnerModule } from "primeng/progressspinner";
import { SidebarModule } from "primeng/sidebar";
import { SliderModule } from "primeng/slider";
import { SpeedDialModule } from "primeng/speeddial";
import { SplitButtonModule } from "primeng/splitbutton";
import { StepsModule } from "primeng/steps";
import { StyleClassModule } from "primeng/styleclass";
import { TableModule } from "primeng/table";
import { TabViewModule } from "primeng/tabview";
import { TagModule } from "primeng/tag";
import { ToastModule } from "primeng/toast";
import { RadioButtonModule } from "primeng/radiobutton";
import { SelectButtonModule } from "primeng/selectbutton";
import { TimelineModule } from "primeng/timeline";
import { SkeletonModule } from "primeng/skeleton";
import { ToggleButtonModule } from "primeng/togglebutton";
import { RippleModule } from "primeng/ripple";
import { RatingModule } from "primeng/rating";
import { BadgeModule } from "primeng/badge";

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    TableModule,
    ButtonModule,
    MenubarModule,
    SplitButtonModule,
    InputTextModule,
    SidebarModule,
    PanelMenuModule,
    CardModule,
    CheckboxModule,
    ReactiveFormsModule,
    MenuModule,
    ChartModule,
    StyleClassModule,
    TabViewModule,
    SpeedDialModule,
    ProgressSpinnerModule,
    DropdownModule,
    InputTextareaModule,
    InputSwitchModule,
    SliderModule,
    ContextMenuModule,
    DropdownModule,
    ChipModule,
    InputMaskModule,
    InputNumberModule,
    MultiSelectModule,
    FileUploadModule,
    CalendarModule,
    CheckboxModule,
    TagModule,
    MessagesModule,
    ToastModule,
    ConfirmDialogModule,
    DialogModule,
    DividerModule,
    StepsModule,
    TimelineModule,
    SkeletonModule,
    ToggleButtonModule,
    RippleModule,
    RatingModule,
    BadgeModule,
  ],
  exports: [
    TableModule,
    ButtonModule,
    MenubarModule,
    SplitButtonModule,
    InputTextModule,
    SidebarModule,
    PanelMenuModule,
    CardModule,
    CheckboxModule,
    ReactiveFormsModule,
    MenuModule,
    ChartModule,
    StyleClassModule,
    TabViewModule,
    SpeedDialModule,
    ProgressSpinnerModule,
    DropdownModule,
    InputTextareaModule,
    InputSwitchModule,
    SliderModule,
    ContextMenuModule,
    DropdownModule,
    ChipModule,
    InputMaskModule,
    InputNumberModule,
    MultiSelectModule,
    FileUploadModule,
    CalendarModule,
    CheckboxModule,
    TagModule,
    MessagesModule,
    ToastModule,
    ConfirmDialogModule,
    DialogModule,
    DividerModule,
    StepsModule,
    RadioButtonModule,
    SelectButtonModule,
    TimelineModule,
    SkeletonModule,
    ToggleButtonModule,
    RippleModule,
    RatingModule,
    BadgeModule,
  ],
})
export class PrimeModule {}
